// import KerelaReqCardView from "../DestUtilityComponents/Kerala/reqCard.js";
// import DubaiReqCardView from "../DestUtilityComponents/Dubai/reqCard.js";
// import KarnatakaReqCardView from "../DestUtilityComponents/Karnataka/reqCard.js";
// import KashmirReqCardView from "../DestUtilityComponents/Kashmir/reqCard.js";
// import HimachalReqCardView from "../DestUtilityComponents/Himachal/reqCard.js";
// import UttrakhandReqCardView from "../DestUtilityComponents/Uttrakhand/reqCard.js";
// import RajasthanReqCardView from "../DestUtilityComponents/Rajasthan/reqCard.js";
// import SikkimDarjeelingReqCardView from "../DestUtilityComponents/Sikkim_Darjeeling/reqCard.js";
// import BhutanReqCardView from "../DestUtilityComponents/Bhutan/reqCard.js";
import GenericReqCardView from "../DestUtilityComponents/Others/reqCard.js";

// import {
//   DestinationNames,
//   DestNameKerala,
//   DestNameDubai,
//   DestNameKarnataka,
//   DestNameKashmir,
//   DestNameHimachal,
//   DestNameUttrakhand,
//   DestNameRajasthan,
//   DestNameSikkimDarjeeling,
//   DestNameBhutan,
// } from "../Constants";

// const DestReqCardMap = {
//   [DestNameKerala.toLowerCase()]: KerelaReqCardView,
//   [DestNameDubai.toLowerCase()]: DubaiReqCardView,
//   [DestNameKarnataka.toLowerCase()]: KarnatakaReqCardView,
//   [DestNameKashmir.toLowerCase()]: KashmirReqCardView,
//   [DestNameHimachal.toLowerCase()]: HimachalReqCardView,
//   [DestNameUttrakhand.toLowerCase()]: UttrakhandReqCardView,
//   [DestNameRajasthan.toLowerCase()]: RajasthanReqCardView,
//   [DestNameBhutan.toLowerCase()]: BhutanReqCardView,
//   [DestNameSikkimDarjeeling.toLowerCase()]: SikkimDarjeelingReqCardView,
// };

const DestReqCardIndex = (props) => {
  let {
    reqData: { destination = "", reqId = "", queryType = "others", priority = "" },
  } = props;
  console.log(
    "DestReqCardIndex ",
    destination,
    priority,
    reqId,
    queryType,
    destination.toLowerCase(),
    // DestReqCardMap,
    // DestReqCardMap[destination.toLowerCase()],
  );
  if (!destination) return <></>;
  const DestReqCardView = GenericReqCardView;
    // queryType == "others"
    //   ? GenericReqCardView
    //   : DestReqCardMap[destination.toLowerCase()];
  return <DestReqCardView {...props} />;
};

export default DestReqCardIndex;
