import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { nanoid } from "nanoid";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import useMediaQuery from "@mui/material/useMediaQuery";

import DestReqCardIndex from "../Commons/destReqCardIndex.js";
import { db } from "../firebaseConfig";
import { BID_DEFAULT_STATUS_TYPE, REQ_BOOKED_STATUS_TYPE, PHONE_FILTER_REGEX, BID_MSGS_MAX_LENGTH } from "../Constants";
import SnackbarMsg from "../Commons/snackbarMsg";
import BidsList from "./bidsList";
import { sendEmail, getUserDetails, devConsolelog } from "../Utility.js";

const UpdateBidView = ({prevBid = null, req, setShowSnackbar, handleUpdateBidView, setUpdateBidFlow}) => {
  // let { state = {} } = useLocation();
  // let { req: data = {} } = state || {};
  // const [req, setReq] = useState(data);

  console.log("update view ---", prevBid, req)
  const [bid, setBid] = useState(prevBid?.bid || "");
  // const [currentUserBid, setCurrentUserBid] = useState([]);
  const [bidPosted, setBidPosted] = useState();
  // const [showSnackbar, setShowSnackbar] = useState({ open: false });
  const [bidMsg, setBidMsg] = useState(prevBid?.message || "");
  const [rateInclusion, setRateInclusion] = useState("All Inclusive");
  const [cabModel, setCabModel] = useState("");
  let { reqId } = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const docRef = doc(db, "agentRequirements", reqId);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  // const currentUserBid = useRef(false);
  const listenerUnsubscribe = useRef();

  const handleBidChange = (e) => {
    let val = e.target.value;
    // add validation for bids
    setBid(val);
  };

  const handlePost = async () => {
    if (!bid.trim()) return;
    console.log("handle bid post", bid, bidMsg, bidMsg.length);
    if (bidMsg.length > BID_MSGS_MAX_LENGTH) {
      setShowSnackbar({
        message: `Max ${BID_MSGS_MAX_LENGTH} characters allowed for Message!`,
        open: true,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        severity: "error"
      });
    }
    handleUpdateBidView(false);

    let updatedBids = req.bids.map(i => {
      if(i.id === prevBid.id) {
        console.log("found Bid to update ", prevBid.id, i.id, bid, bidMsg, Date.now())
        i["bid"] = bid;
        i["message"] = bidMsg;
        i["updatedAt"] = Date.now();
        i["status"] = "pending"
        i["cabModel"] = cabModel
        i["rateInclusion"] = rateInclusion
      }
      return i;
    })
    
    await updateDoc(docRef, {
      bids: updatedBids,
    });

    // let agentDetails = await getUserDetails(req.agentId);
    // let { companyDetails={}  } = agentDetails;
    // let { companyName = "" }= companyDetails;
    // devConsolelog("Update bid mail to agent ", req.agentId, `${process.env.REACT_APP_CABEASY_DOMAIN}${process.env.REACT_APP_CHECK_BIDS_URL_PATH}${reqId}`)
    // await sendEmail({
    //   to: agentId,
    //   // name: userData.name,
    //   template: {
    //     name: "bid_received_agent",
    //     data: {
    //       name: agentDetails?.companyName || agentDetails?.name,
    //       startDateView: format(new Date(req.startDate), "dd-MMMM-yyyy") || "",
    //       ...req,
    //       reqDetailLink: `${process.env.REACT_APP_CABEASY_DOMAIN}${process.env.REACT_APP_CHECK_BIDS_URL_PATH}${reqId}`
    //     },
    //   },
    // });

    setUpdateBidFlow(false);
    setTimeout(() => navigate("/home"), 1000);
  };
  console.log('update bid render ', bid, prevBid.bid);

  if(!prevBid) return null;

  return (
    <>
      <Box>
        <Box sx={{ mt: 4 }}>
          <center>

            <Box sx={{ mt: { xs: 5, m: 10 }, maxWidth: 545 }}>
              {
                bidPosted ? (
                <p style={{ background: "#fff7db" }}>
                  Your bid has been updated! Please wait while it's
                  being reviewed by the agent. Once it's approved, you'll be
                  notified.
                </p>
              ) : (<>
                <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                  <InputLabel htmlFor="bidInput">
                    Updated Bid*
                  </InputLabel>
                  <OutlinedInput
                    id="bidInput"
                    startAdornment={
                      <InputAdornment position="start">INR</InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">{rateInclusion ? `/${rateInclusion}` : ''}</InputAdornment>
                    }
                    label="post your bid"
                    onChange={(e) => handleBidChange(e)}
                    type="number"
                    defaultValue={bid}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                  <InputLabel htmlFor="bidMsgInput">
                    Update Inclusions Or CabType
                  </InputLabel>
                  <OutlinedInput
                    id="bidMsgInput"
                    label="Message to agent"
                    onChange={(e) => setBidMsg(e.target.value.replace(PHONE_FILTER_REGEX, ' '))}
                    type="text"
                    defaultValue={bidMsg}
                  />
                </FormControl>
                <br />
                <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                  <InputLabel htmlFor="cabModelInput">
                    Cab Model Options
                  </InputLabel>
                  <OutlinedInput
                    id="cabModelInput"
                    onChange={(e) => setCabModel(e.target.value.replace(PHONE_FILTER_REGEX, " "))}
                  />
                </FormControl>
                <br />
                <FormControl sx={{ alignItems: 'flex-start' }}>
                  <FormLabel id="rate-inc-select">Select your Rate Inclusions:</FormLabel>
                  <RadioGroup
                    row={!isMobile}
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="All Inclusive"
                    name="radio-buttons-group"
                    id="rate-inc-select"
                    onChange={(e) =>
                      setRateInclusion(e.target.value)

                    }
                  >
                    <FormControlLabel value="All Inclusive" control={<Radio size="small" />} label="All Inclusive" />
                    <FormControlLabel value="Parking not Included" control={<Radio size="small" />} label="Parking not Included" />
                    <FormControlLabel value="State not Included" control={<Radio size="small" />} label="State not Included" />
                  </RadioGroup>
                </FormControl>
              </>)}
            </Box>
            
            <Button
              sx={{ mr: 1, flexGrow: 1, m: "8px 0px" }}
              variant="contained"
              // size="small"
              onClick={handlePost}
              disabled={bidPosted}
              endIcon={<SendIcon />}
            >
              Update Bid
            </Button>
          </center>
        </Box>
      </Box>
      <div style={{ margin: "1rem auto", maxWidth: "10em" }}>
        <Button
          onClick={() => handleUpdateBidView(false)}
          fullWidth
          variant="outlined"
          color="secondary"
          size="small"
          sx={{fontSize: '10px', maxWidth: 8}}
        >
          
          Cancel
        </Button>
      </div>
    </>
  );
};

export default UpdateBidView;
