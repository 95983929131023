import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
// import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from "@mui/material/CircularProgress";
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardMedia';
import useMediaQuery from "@mui/material/useMediaQuery";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { nanoid } from "nanoid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useFileUpload from "react-use-file-upload";
import { doc } from "firebase/firestore";

import { db, storage } from '../firebaseConfig';
import { CabEasyAccDetails, CabEasyPayGpayNo } from '../Constants';
import { isEmptyObject } from '../Utility';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >•</Box>
);

const  {
  accName,
  bankName,
  branch,
  accNo,
  ifsc
} = CabEasyAccDetails || {};

const userData = JSON.parse(localStorage.getItem("user"));

const FdBookingAccDetailsView = ({ initiateBooking, acceptedBid = {}, bookLoading, setBookLoading }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [fileUploaded, setFileUploaded] = useState(false);
  const [tokenAmt, setTokenAmt] = useState();
  const { files, setFiles } = useFileUpload();
  const { reqId } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef();

  useEffect(() => {
    if(!acceptedBid || isEmptyObject(acceptedBid) || !Number(acceptedBid.bid)) return;
    // set token amount
    setTokenAmt(Math.ceil(Number(acceptedBid.bid) * 0.3));
  }, [acceptedBid])

  useEffect(() => {
    if (fileUploaded && files && files.length > 0) {
      setBookLoading(true);
      const docRef = doc(db, "bookings", reqId);
      let dbFileUrls = files.map(async (file, index) => {
        let dbFileName = `${nanoid(5)}_${reqId}_token_sc_${files[index]?.name}`;
        const storageRef = ref(storage, `payment-docs/${dbFileName}`);
        try {
          let snapshot = await uploadBytes(storageRef, file);
        } catch (e) {
          console.error("handle send upload error!! ", e);
          return;
        }

        let url = await getDownloadURL(storageRef);
        return {
          name: files[index]?.name,
          url,
        };
      });
      console.log("token dbFileUrls", dbFileUrls)
      let finalDbData = null;
      Promise.all(dbFileUrls)
      .then(async (data) => {
        let tokenScUrls = data.map(item => item.url);
        // data.map((item) => {
        //   return {
        //     id: nanoid(5),
        //     sender: userData.phone,
        //     type: "file",
        //     fileUrl: item.url,
        //     fileName: item.name,
        //     createdAt: Date.now(),
        //     header: "Token Payment"
        //   };
        // });

        // create booking with initiated state.
        console.log("token upload done ", acceptedBid, tokenAmt, tokenScUrls)
        initiateBooking(acceptedBid, tokenAmt, tokenScUrls);
        // await updateDoc(docRef, {
        //     chats: arrayUnion(...finalData, BOOKING_COMPLETED),
        //     completePaymentRecieved: true
        // });
      })
      .catch((e) => {
        console.log("token file P error -", e)
        return;
        setBookLoading(false);
      });
    } 
    // else {
    //   await updateDoc(docRef, {
    //     chats: arrayUnion(
    //       {
    //           id: nanoid(5),
    //           sender: userData.phone,
    //           type: "text",
    //           text: inputText,
    //           createdAt: Date.now(),
    //           header: "Payment to CabEasy"
    //       },
    //       BOOKING_COMPLETED
    //     ),
    //     completePaymentRecieved: true
    //   });
    // }
  }, [fileUploaded]);

  return (<Box>
    {/* <Card sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', minWidth: 275 }}> */}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardHeader>
          <Typography sx={{ fontSize: 14, p: 2 }} color="text.secondary">
            <b>{`Token Amount (30%) = INR ${tokenAmt}`}</b>
          </Typography>
        </CardHeader>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Bank Account Details:
          </Typography>
          <Typography variant="h6" component="div">
            Acc Name: &nbsp; {bull} &nbsp; {accName}
          </Typography>
          <Typography variant="h6" component="div">
            Bank Name &nbsp; {bull} &nbsp; {bankName}
          </Typography>
          {/* <Typography variant="h6" component="div">
             &nbsp; {bull} &nbsp; {branch}
          </Typography> */}
          <Typography variant="h6" component="div">
            Acc No: &nbsp; {bull} &nbsp; {accNo}
          </Typography>
          <Typography variant="h6" component="div">
            IFSC: &nbsp; {bull} &nbsp; {ifsc}
          </Typography>
          <br />
          <Typography variant="caption" component="div">
            *Booking will be initiated and confirmed after payment verification.<br />
            *Verification may take 1 business day.<br />
            *You will get a message on our official whatsapp, when payment is confirmed.
          </Typography>

          {/* <Typography sx={{ mb: 1.5 }} color="primary">
            Or
          </Typography>

          <Typography variant="body2">
            Gpay Number:
          </Typography>
          <Typography variant="h6" component="div">
            {bull} &nbsp; {CabEasyPayGpayNo}
          </Typography> */}
        </CardContent>
        <CardActions>
          <Button variant="outlined" size='small' color="primary" onClick={() => inputRef.current.click()} disabled={bookLoading}>
            Upload Payment Screenshot
            &nbsp; 
            {
              bookLoading
              ? <CircularProgress color={"primary"} size={20} sx={{ m: 1 }} />
              : <UploadFileIcon sx={{ verticalAlign: "middle", color: "primary", padding: "1px" }} />}
          </Button>
          {/* <LoadingButton
            loading
            loadingIndicator="Loading…"
            variant="outlined"
            size='small' 
            color="primary" 
            onClick={() => inputRef.current.click()}
          >
            Upload Payment Screenshot
            &nbsp;<UploadFileIcon sx={{ verticalAlign: "middle", color: "primary", padding: "1px" }} />
          </LoadingButton> */}
          <input
            ref={inputRef}
            type="file"
            multiple
            style={{ display: "none" }}
            onChange={(e) => {
                setFiles(e, "a");
                inputRef.current.value = null;
                setTimeout(() => {
                  setFileUploaded(true);
                }, 100);
            }}
          />
        </CardActions> 
      </Box>
      {/* <CardMedia
        component="img"
        sx={{ width: 'auto', flexGrow: 1, m: 2 }}
        image="https://firebasestorage.googleapis.com/v0/b/persuasive-ego-377212.appspot.com/o/website%2FScreenshot%202024-05-28%20152802.png?alt=media&token=cbc12c6a-269c-4a65-b324-9e0540947715"
        alt="bank acc details"
      /> */}
    {/* </Card> */}
  </Box>);
}

export default FdBookingAccDetailsView;