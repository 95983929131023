import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import loadable from '@loadable/component';
import Typography from '@mui/material/Typography';

import AppHome from "./AppHome/index.js";
import PostBid from "./Bids/postBid";
import ViewBids from "./Bids/viewBids";
// import BookingDetail  from "./BookingDetails/index.js";
// import MyBookings from "./Bookings/index.js";
import Login from "./Login/index";
import UserProfile from "./Profile/index";
import SignUp from "./SignUp/index";
import ErrorPage from "./error-page";
import Logout from "./logout.js";
// import PostReq from "./postReq/index.js";
import ViewRequirements from "./viewRequirements/index.js";
import AppLayout from "./layout/index.jsx";
// import CabEasyHome from "./Homepage/index.js";
import FaqSection from "./Faq/FaqContainer.jsx";
import CancellationSection from "./Faq/cancellationPolicy.js";
import ServiceDeliveryPolicy from "./Faq/serviceDeliveryPolicy.js";
import ContactUsSection from "./Faq/contactUsSection.js";
import Accounts from "./Accounts/accounts.jsx";
import KycPending from "./SignUp/kycPending.js";
// import TermsOfUse from "./Homepage/termsOfUse.js";
import PrivacyPolicy from "./Homepage/privacyPolicy.js";
import UnprotectdedAppLayout from "./layout/unprotectedLayout.jsx";
import MyComplaints from "./myComplaints/index";
import FeedBackFormContainer from "./FeedbackForm/feedbackFormContainer.jsx";
// import FixedDeparture from "./FixedDepartures/index.js";
// import FixedDepartureDetail from "./FixedDepartures/fdDetails.js";
import ReferalsView from "./Referal/index.js";
import NegotiateBid from "./Bids/negotiateBid.js";
// import CabbieBot from "./CabbieBot/index.js";
import DealsPageContainer from "./DealsAndOffer/index.js"
// import SeoLandingView from "./DestUtilityComponents/seoLandingindex.js"
// import FeedbackPage from "./BookingDetails/BookingProcess/feedbackPage.jsx";
import OfficialWALanidngPage from "./Landings/OfficialWALanding.js";
// import NewLandingPage from "./Homepage/newLanding.js";

const CabEasyHomeLazy = loadable(
  () => import('./Homepage/index.js'),
  {
    fallback: <Typography variant="caption">Loading...</Typography>
  }
);

const TermsOfUseLazy = loadable(
  () => import('./Homepage/termsOfUse.js'),
  {
    fallback: <Typography variant="caption">Loading...</Typography>
  }
);

const PostReqLazy = loadable(
  () => import('./postReq/index.js'),
  {
    fallback: <Typography variant="caption">Loading...</Typography>
  }
);

const MyBookingsLazy = loadable(
  () => import('./Bookings/index.js'),
  {
    fallback: (<Typography variant="caption">Loading...</Typography>)
  }
);

const BookingDetailLazy = loadable(
  () => import('./BookingDetails/index.js'),
  {
    fallback: (<Typography variant="caption">Loading...</Typography>)
  }
);

const SeoLandingViewLazy = loadable(
  () => import('./DestUtilityComponents/seoLandingindex.js'),
  {
    fallback: (<Typography variant="caption">Loading...</Typography>)
  }
);

const FixedDepartureLazy = loadable(
  () => import('./FixedDepartures/index.js'),
  {
    fallback: (<Typography variant="caption">Loading...</Typography>)
  }
);

const FixedDepartureDetailLazy = loadable(
  () => import('./FixedDepartures/fdDetails.js'),
  {
    fallback: (<Typography variant="caption">Loading...</Typography>)
  }
);
const ShowPboPagesLazy = loadable(
  () => import('./BookingDetails/showPBOPages.js'),
  {
    fallback: (<Typography variant="caption">Loading...</Typography>)
  }
);

const router = createBrowserRouter([
  //Unprotected Routes
  {
    path: "/",
    element: <CabEasyHomeLazy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-of-use",
    element: (
      <UnprotectdedAppLayout>
        <TermsOfUseLazy />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/privacy-policy",
    element: (
      <UnprotectdedAppLayout>
        <PrivacyPolicy />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/logout",
    element: <Logout />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: (
      <UnprotectdedAppLayout>
        <FaqSection />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/cancellation-policy",
    element: (
      <UnprotectdedAppLayout>
        <CancellationSection />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/service-delivery-policy",
    element: (
      <UnprotectdedAppLayout>
        <ServiceDeliveryPolicy />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact-us",
    element: (
      <UnprotectdedAppLayout>
        <ContactUsSection />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },


  // Protected Routes

  {
    path: "/home",
    element: (
      <AppLayout>
        <AppHome />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/kyc-pending",
    element: (
      <UnprotectdedAppLayout>
        <KycPending />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/post-req",
    element: (
      <AppLayout>
        <PostReqLazy />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/my-reqs",
    element: (
      <AppLayout>
        <ViewRequirements />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/req/post-bid/:reqId",
    element: (
      <AppLayout>
        <PostBid />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/req/view-bids/:reqId",
    element: (
      <AppLayout>
        <ViewBids />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/my-bookings",
    element: (
      <AppLayout>
        <MyBookingsLazy />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/booking/:bookingId",
    element: (
      <AppLayout>
        <BookingDetailLazy />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/booking/:bookingId/:pboType",
    element: (
      <AppLayout>
        <ShowPboPagesLazy />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: (
      <AppLayout>
        <UserProfile />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/accounts",
    element: (
      <AppLayout>
        <Accounts />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/raise-complaint",
    element: (
      <AppLayout>
        <MyComplaints />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/feedback",
    element: (
      <AppLayout>
        <FeedBackFormContainer />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/fds",
    element: (
      <AppLayout>
        <FixedDepartureLazy />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/fds/:fdId",
    element: (
      <AppLayout>
        <FixedDepartureDetailLazy />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/referral",
    element: (
      <AppLayout>
        <ReferalsView />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/cabbie",
  //   element: (
  //     <AppLayout>
  //       <CabbieBot />
  //     </AppLayout>
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/deals",
    element: (
      <UnprotectdedAppLayout>
        <DealsPageContainer />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/explore/:destination",
    element: (
      <UnprotectdedAppLayout>
        <SeoLandingViewLazy />
      </UnprotectdedAppLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/req/post-bid/:reqId/update",
    element: (
      <AppLayout>
        <NegotiateBid />
      </AppLayout>
    ),
  },
  {
    path: "/whatsapp/landing",
    element: (
      <AppLayout>
        <OfficialWALanidngPage />
      </AppLayout>
    ),
  }
]);

const Routes = () => {
  // useEffect(() => {
  // if(isLoggedIn) window.location = "/home";
  // }, []);

  return <RouterProvider router={router} />;
};

export default Routes;
