import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";

import BidRequestCard from "../Commons/bidRequestCard";
import { MainContext } from "../Utility";

const BidsList = (props) => {
  let { bids = [],reqData={}, isAgent, ...rest } = props;
  const { userData, isUserAgent } = useContext(MainContext);

  let ceBestBidIndex = bids.findIndex((i) => i.ceBestBid);
  if (ceBestBidIndex === -1) ceBestBidIndex = 0;

  //  const ceBestBidIndex = bids.findIndex((bid) => bid.ceBestBid);
  const currentPath = useLocation().pathname;
  console.log("ceBestBidIndex ", ceBestBidIndex);
  return (
    <List sx={{ width: "100%", maxWidth: 600, margin: "auto" }}>
      {bids?.map((bid, bidIndex) => {
        let {
          id = "",
          bid: price = "",
          supplierId = "",
          ceBestBid = false,
          agentNegotiateAttempts,
          rank=1,
          totalLength=1
        } = bid;

        return (
          <ListItem alignItems="flex-start" key={id} sx={{ margin: "auto" }}>
            <ListItemText
              primary={
                !isUserAgent && agentNegotiateAttempts == 1 ? (
                  <BidAlert
                    alertMessage="Agent requested for a better price:"
                    buttonText="Update your Bid"
                    url={currentPath}
                  />
                ) : null
              }
              secondary={
                <BidRequestCard
                  bid={bid}
                  price={price}
                  bestBid={ceBestBidIndex === bidIndex}
                  reqData={reqData}
                  {...rest}
                />
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default BidsList;

const BidAlert = ({ alertMessage, buttonText, url }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body2" color="green" sx={{ marginInline: "1rem" }}>
        {" "}
        {alertMessage}
      </Typography>
      <Button href={url + "/update"} size="small" variant="text">
        {buttonText}
      </Button>
    </Box>
  );
};
