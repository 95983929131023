import React, { useEffect } from 'react';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import LinkIcon from "@mui/icons-material/Link";
import Link from "@mui/material/Link";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

import { KYC_FAQ_DATA } from "../Faq/kyc-faq-data";
import { KYC_FORM_LINK, OFFICIAL_WA_LINK } from "../Constants.js";

const KYCFaqSection = () => {
  return (
    <>
      {KYC_FAQ_DATA.map((faq, i) => {
        return (
          <Accordion id={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={500}>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#ffeba0" }}>
              <Typography>{faq.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

const KycPending = () => {
  const userData = JSON.parse(localStorage.getItem("user") || null);
  const isUserAgent = Boolean((userData || {}).isAgent);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      localStorage.setItem("kycPopUpTime", Date.now());
    } catch (e) {
      console.log("kycPopUpTime");
    }
  }, [])

  return (
      <Container>
        <center>
          <h3>Verify your KYC with CabsEasy.</h3>
          <p>
            To ensure the security of your account and comply with regulatory
            requirments, we need to verify your identity.
            <b>It will be done in 5 mins!</b>
          </p>
          {
            !isUserAgent && (<p>
              It is <b>mandatory</b> for Supplier Accounts to Verify their KYC according to our policy!
              You cannot Post Bids or accept Bookings without verification.
            </p>)
          }
          <p>Please login again when you receive the kyc verification email. 
            <b>The link will be availble in the email.</b> 
          </p>
          <div style={{ margin: "30px 0px" }}>
            <KYCFaqSection />
          </div>
          <div style={{ maxWidth: "300px" }}>
            <Button autoFocus
              fullWidth
              sx={{ mr: 1, flexGrow: 1, m: "8px 0px" }}
              variant="contained"
              onClick={() =>
                window.open(
                  KYC_FORM_LINK,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              endIcon={<LinkIcon />}
            >
              Complete KYC
            </Button>
          </div>
          
          <p>
            If you are facing any issues or doubts, please connect with us on our official Whatsapp:&nbsp;
            <Link
              href={OFFICIAL_WA_LINK}
              variant="body2"
              sx={{
                textAlign: "center",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              <b>Connect on WhatsApp</b>
            </Link>
          </p>
          
          {
            (isUserAgent && window.location.pathname == "/kyc-pending" ) && (<div style={{ maxWidth: "300px" }}>
              <Button 
                autoFocus
                size="small"
                sx={{ mr: 1, flexGrow: 1, m: "8px 0px" }}
                variant="outlined"
                onClick={() =>
                  navigate("/home")
                }
              >
                Skip For now
              </Button>
            </div>)
          }
        </center>
      </Container>
  );
};

export default KycPending;
