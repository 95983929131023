import React, { useState, useEffect, useRef } from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import useFileUpload from 'react-use-file-upload';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { nanoid } from 'nanoid';
import  Typography  from '@mui/material/Typography';
import axios from 'axios';
import loadable from '@loadable/component'

// import VoucherTemplate from './voucherTemplate.js';
import { devConsolelog, getBase64, isEmptyObject, urlToBase64 } from '../Utility.js';
// import { aiStringItitoJsonIti } from "../utills/aiUtils.js";
import { db, storage } from '../firebaseConfig.js';

const VoucherTemplate = loadable(() => import('./voucherTemplate.js'));

const DownloadVoucherView = ({ open = false, bidData = null, setOpenVoucherModal, reqData = null}) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const [margin, setMargin] = useState("");
  const [totalPrice, setTotalPrice] = useState(bidData.bid);
  // const [aiLoading, setAiLoading] = useState(true);
  const [voucherData, setVoucherData] = useState({});
  const [showVoucher, setShowVoucher] = useState(false);
  const [newUserData, setNewUserData] = useState(userData);
  const [logoUploaded, setLogoUploaded] = useState(true);
  const { files, setFiles } = useFileUpload(true);
  // const [profileDetails, setProfilDetails] = useState(userData);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMargin, setShowMargin] = useState(false);
  const inputRef = useRef();
  let { companyDetails: { logo: logoUrl } = {}, firebaseIdToken = ''} = newUserData;
  // const logoUrl = userData?.companyDetails?.logo;
  // const logoUrl = "https://firebasestorage.googleapis.com/v0/b/persuasive-ego-377212.appspot.com/o/user-docs%2FSB_pr_logo_%2B917880473111_CE-logo-qr-print.png?alt=media&token=84908846-8cc6-4a7b-a605-50e709bb7ff3"
  // const logoUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeeDIo8cHxc9xxaWnu4mJhd6jNyoT0M4Nefw&s"

  console.log("CHECKBIDDATAINSIDE:", bidData, userData, logoUploaded);
  console.log("USERDATACHECK:", userData);
  console.log("REQCHECKDATAINSIDE:",reqData);

  // useEffect(() => {
  //   if (!bidData || isEmptyObject(bidData)) return;

  //   setVoucherData(bidData);
  //   setTotalPrice(parseFloat(bidData.bid) || 0);
    
  //   handleAiUtil();
  // }, [bidData]);

  // const handleAiUtil = async () => {
  //   console.log("json iti request", bidData?.req?.otherInfo);
  //   let res = await aiStringItitoJsonIti({ itineraryText: bidData?.req?.otherInfo });

  //   setTimeout(() => {
  //     setAiLoading(false);
  //     setVoucherData(prev => ({ ...prev, jsonIti: res }));
  //   }, 1000);
  // };

  const getLogoB64encoded = async () => {
    const axiosOptions = {
      method: 'POST',
      headers: { 
          // 'content-type': 'application/x-www-form-urlencoded',
          'Authorization': firebaseIdToken 
      },
      data: {
        logoUrl: logoUrl
      },
      url: `${process.env.REACT_APP_CE_API_BASE_URL}/api/get-logo-b64`,
    };
    console.log('request axios ', axiosOptions);

    let response = await axios(axiosOptions);
    console.log('response axios ', response);
    setNewUserData(prev => ({ ...prev, logoB64Str: response.data?.data }));
  }
 
  useEffect(() => {
    console.log("CHECKBIDDATAINSIDE axios LOGO - ", logoUrl)
    if(!logoUrl) {
      setLogoUploaded(false);
    } else {
      //fetch logo base64
      
      getLogoB64encoded();
    }
  }, [logoUrl]);
  
  
  const handleMarginChange = (e) => {
    const marginValue = e.target.value;
    setMargin(marginValue);

    const marginAmount = parseFloat(marginValue) || 0;
    setTotalPrice((parseFloat(bidData.bid) || 0) + marginAmount);
  };

  // const handleViewVoucher = async() => {
  //   setLoading(true);
  //   const tempData = await urlToBase64(url)
  //   console.log("CHECKB64DATAURL", url);
  //   console.log("CHECKB64DATA", tempData);
  //   if (voucherData) {
  //     setVoucherData(prev => ({
  //       ...prev,
  //       totalPrice: totalPrice
  //     }));
  //     setShowVoucher(true);
  //   }
  //   setLoading(false);
  // };
  useEffect(() => {
    if (voucherData) {
      setShowVoucher(true);
    }
  }, [voucherData]);

  // const handleViewVoucher = async () => {
  //   setLoading(true);
  
  //   try {
  //     console.log("handleViewVoucher: Calling urlToBase64 with URL", logoUrl);
  //     const tempData = await urlToBase64(logoUrl);
  
  //     console.log("handleViewVoucher: Received base64 data", tempData);
  
  //     if (tempData) {
  //       setVoucherData(prev => ({
  //         ...prev,  
  //         totalPrice: totalPrice,
  //         fileB64: tempData,
  //       }));
  //       setTimeout(() => {
  //       });
  //     } else {
  //       console.error("handleViewVoucher: Base64 data is null or undefined");
  //     }
  //   } catch (error) {
  //     console.error("handleViewVoucher: Error in converting URL to base64", error);
  //   }
  
  //   setLoading(false);
  // };
  const handleViewVoucher = async () => {
    setLoading(true);
    try {
      console.log("handleViewVoucher:  URL", logoUrl);
      const tempData = null  //await urlToBase64(logoUrl);
      console.log("tempDATA", tempData);
  
      if (tempData) {
        console.log("YES IT IS")
        setVoucherData({
          ...bidData,
          totalPrice: totalPrice,
          logoB64Str: tempData,
          showMarginPrice : showMargin,
        });
      } else {
        setVoucherData({
          ...bidData,
          totalPrice: totalPrice,
          showMarginPrice : showMargin,
        });
      }
    } catch (error) {
      console.error("Error in converting URL to base64", error);
    }
    setLoading(false);
  };
  
  useEffect(() => {
    if (fileUploaded) {
      handleSend("file");
    }
  }, [fileUploaded]);

  const handleSend = async (type = "text") => {
    setLoading(true);
    const docRef = doc(db, "userDetails", userData.phone);
    devConsolelog("handleSend ===> ", type, files);
  
    let dbFileUrls = files.map(async (file, index) => {
      let dbFileName = `${nanoid(5)}_logo_${userData.phone}_${files[index]?.name}`;
      const storageRef = ref(storage, `user-docs/${dbFileName}`);
      try {
        await uploadBytes(storageRef, file);
      } catch (e) {
        console.error("handle send upload error!! ", e);
        return;
      }
      // await handleSavePdfData(file);
      let url = await getDownloadURL(storageRef);
      return {
        name: files[index]?.name,
        url,
      };
    });
  
    devConsolelog("handleSend files", dbFileUrls);
    let finalDbData = null;
  
    try {
      const data = await Promise.all(dbFileUrls);
      devConsolelog("handleSend db file urls", data);
      let finalData = data.map((item) => {
        devConsolelog("handleSend db file url item", item, item.url);
        return item.url;
      });
  
      let updatedProfileData = {
        ...userData,
        companyDetails: {
          ...userData.companyDetails,
          logo: finalData[0],
        },
      };
      await updateDoc(docRef, updatedProfileData);
      
      localStorage.setItem("user", JSON.stringify(updatedProfileData));
      setNewUserData(prev => ({ 
        ...prev, 
        companyDetails: { 
          ...prev.companyDetails, 
          logo: finalData[0] 
        } 
      }));
    } catch (e) {
      console.log("logo file error -", e);
    } finally {
      setLoading(false);
    }
  };
  const handleShowMargin = () => {
    console.log("SHOWMARGIN", showMargin);
    setShowMargin(showMargin);
  }
  // const handleSavePdfData = async (companyLogo) => {
  //   setLoading(true);
  //   // setCompanyInfo({ ...companyInfo, companyLogo });
  //   let fileB64 = await getBase64(companyLogo);
  //   console.log("fileB64 ", fileB64, companyLogo);
  //   // let docRef = doc(db, "userPdfData", userData.phone)
  //   // await setDoc(docRef, {
  //   //   logoB64Str: fileB64
  //   // }, { merge: true });
  //   setNewUserData(prev => ({ ...prev, logoB64Str: fileB64 }));
  // }
  useEffect(() => {
    if (newUserData.logoB64Str) {
      console.log("Logo successfully updated in newUserData:", newUserData.logoB64Str);
      setLogoUploaded(true);
      setLoading(false);
    }
  }, [newUserData.logoB64Str]);
  if (!voucherData || loading) return <CircularProgress color={"primary"} sx={{ mt: 2, mx: 'auto', textAlign: 'center' }} />;

  return (
    <Box>
      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
        {
          (!logoUploaded) && (<Box sx={{my: 1, ml: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Typography variant='subtitle1'> Upload Your Logo</Typography>
            &nbsp;&nbsp;  
            <Button variant="outlined" startIcon={<UploadFileIcon />} size="small" aria-label="upload-logo"
              onClick={() => inputRef.current.click()}
            >Upload Logo</Button>
            <input
              ref={inputRef}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                setFiles(e, "a");
                inputRef.current.value = null;
                setFileUploaded(true);
              }}
            />
          </Box>)
        }
        <Box sx={{ ml: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant='subtitle1' >CabEasy Price: </Typography>
          <Typography variant='subtitle1' >Rs. {bidData.bid}</Typography>
        </Box>
        <Box sx={{my: 1, ml: 1, display: 'flex', flexDirection: 'column'}}>
          <InputLabel htmlFor="outlined-adornment-amount">
            Enter your Margin
          </InputLabel>
          <OutlinedInput
            id="marginInput"
            startAdornment={
              <InputAdornment position="start">INR</InputAdornment>
            }
            onChange={handleMarginChange}
            type="number"
          />
          {/* <input
            type="number"
            placeholder="Enter Margin in INR"
            value={margin}
            onChange={handleMarginChange}
            style={{ padding: '8px', marginBottom: '16px', width: '200px' }}
          /> */}
        </Box>
        {/* <Box alignItems={'left'} sx={{ ml: 1, mb: 1 }}>
          <Typography variant='subtitle1' textAlign={'left'}>Total Price: Rs. {totalPrice}</Typography>
        </Box> */}
        <Box sx={{ ml: 1, mb: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant='subtitle1' >Total Price: </Typography>
          <Typography variant='subtitle1' >Rs. {totalPrice}</Typography>
        </Box>
        <Box sx={{ my: 1, ml: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <input
            type="checkbox"
            id="showMarginCheckbox"
            // checked={showMargin}
            onChange={() => setShowMargin(!showMargin)}
            onClick={handleShowMargin}
          />
          <label htmlFor="showMarginCheckbox" style={{ marginLeft: '8px' }}>
            Show Margin
          </label>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleViewVoucher}
          sx={{ mb: 2, width: 'fit-content', mx: 'auto' }}
        >
          Download Voucher
        </Button>
      </Box>
      {showVoucher && voucherData && !isEmptyObject(voucherData) && (
        <VoucherTemplate 
          bidData={voucherData} 
          userData={newUserData}
          reqData={reqData}
          // showMargin={showMargin}
        />
      )}
    </Box>
  );
}

export default DownloadVoucherView;
