import { nanoid } from "nanoid";
const userData = JSON.parse(localStorage.getItem("user"));

export const CONFIRM_ITINERARY = {
    id : nanoid(5),
    createsAt:Date.now(),
    ctaDataAgent:[
        {
            actionType: "confirm_itinerary",
            name : "Confirm Itinerary",
        },
    ],
    ctaDataSupp:[
        {
            actionType:"confirm_itinerary",
            name : "Confirm Itinerary",
        }
    ],
    sender: "cb-system",
    text : "Please View and Confirm the Itinerary",
    type:"cta"
}

export const SUBMIT_CAB_DETAILS = {
    id: nanoid(5),
    createdAt: Date.now(),
    sender: "cb-system",
    type: "cta",
    ctaDataSupp: [
        {
            actionType: "submit_cab_details",
            name: "Driver & Cab Details",
        },
    ],
    textAgent: "Supplier will share Driver and Cab Details 1 day before Trip start date.",
    textSupp: "Please share the Cab & Driver Details",
}

export const SUBMIT_ARRIVAL_DETAILS = {
    id: nanoid(5),
    createdAt: Date.now(),
    ctaDataAgent: [
        {
            actionType: "submit_arrival_details",
            name: "Submit Arrival/Hotel Details"
        }
    ],
    sender: "cb-system",
    textAgent: "Please share Client Arrival/Hotel Details asap",
    textSupp: "Agent will share Client Arrival/Hotel Details asap",
    type: "cta",
}

export const PAYMENT_OPT = {
    id: nanoid(5),
    createdAt: Date.now(),
    sender: "cb-system",
    type: "cta",
    ctaDataAgent: [
        {
            actionType: "pay_to_cb",
            name: "Pay via CabEasy",
        },
        // {
        //     actionType: "pay_to_supp",
        //     name: "Pay to Supplier",
        // },
    ],
    textAgent: "Select your payment method",
    textSupp: "We asked the Agent for payment",
}

export const SUBMIT_PAYMENT_DETAILS = {
    id: nanoid(5),
    createdAt: Date.now(),
    sender: "cb-system",
    ctaDataSupp: [
        {
            actionType: "payment_details",
            name: "Submit Payment Details"
        },
    ],
    type: "cta",
    textAgent: "We asked Supplier for Payement Details",
    textSupp: "Please share the payment details to Agent",
}

export const PAYMENT_PROOF = {
    id: nanoid(5),
    createdAt: Date.now(),
    sender: "cb-system",
    ctaDataAgent: [
        {
            actionType: "upload_payment_proof",
            name: "Submit Payment Proof"
        },
    ],
    type: "cta",
    textAgent: "Please submit the successful payment proof",
    textSupp: "Agent will pay soon.",
}

export const BOOKING_COMPLETED = {
    id: nanoid(5),
    createdAt: Date.now(),
    sender: "cb-system",
    ctaDataAgent: [
        {
            actionType: "booking_completed",
            name: "Booking Completed"
        },
    ],
    ctaDataSupp: [
        {
            actionType: "booking_completed",
            name: "Booking Completed"
        },
    ],  
    type: "cta",
    text: "Confirmation of Booking Completion.",
}