import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { doc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { act } from "react";

import AppSubHeader from "../Commons/appSubHeader";
import DestReqCardIndex from "../Commons/destReqCardIndex";
import BidsList from "./bidsList";
import AppLoader from "../layout/Loading";
import NoRecordsFound from "../layout/NoRecordsFound";
import { db } from "../firebaseConfig";
import {
  BID_DECLINED_STATUS_TYPE,
  REQ_BOOKED_STATUS_TYPE,
  BID_DEFAULT_STATUS_TYPE,
  BOOKING_CHAT_TYPE_SYSTEM,
  PBO_STEP_ARRIVAL_DETAILS,
  PBO_STEP_CONFIRM_ITI
} from "../Constants";
import {
  getUserDetails,
  sendEmail,
  devConsolelog,
  addUserActivity,
  isEmptyObject,
} from "../Utility";
import SnackbarMsg from "../Commons/snackbarMsg";
import PopUp from "../Commons/messagePopUp";
import CEPayAccDetailsView from "../Commons/bookingTokenPay.js";
import MyDailog from "../Commons/myDailog.jsx";
import { PAYMENT_OPT, SUBMIT_ARRIVAL_DETAILS, SUBMIT_CAB_DETAILS } from '../BookingDetails/PBOComponents/CtaChatData';

const ViewBids = () => {
  let { state = {} } = useLocation();
  let { req: data = null } = state || {};
  const [req, setReq] = useState(data || {});
  const [bids, setBids] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({ open: false });
  let { reqId } = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const reqRef = doc(db, "agentRequirements", reqId);
  const listenerUnsubscribe = useRef();
  const [openAccept, setOpenAccept] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [openNegotiate, setOpenNegotiate] = useState(false);
  const confirmNewBookingId = useRef(null);
  const [showBookingTokenPaymentView, setShowBookingTokenPaymentView] = useState({
    open: false
  });
  const [bookLoading, setBookLoading] = useState(false);

  devConsolelog("view bids render 1", state, req);
  const RedirectComponent = () => {


    useEffect(() => {
      const timer = setTimeout(() => {
        navigate(`/booking/${req.bookingId}`);
      }, 5000);

      return () => clearTimeout(timer);
    }, [navigate]);

    return <div style={{ fontSize: "10px" }}>Redirecting in 5 seconds...</div>;
  };
  const handleBids = (bids) => {
    let validBids = [...(bids || [])];
    devConsolelog("reqs data handleBids ", validBids);
    validBids = validBids.filter((i) => {
      let status = i.status || BID_DEFAULT_STATUS_TYPE;
      return status === BID_DEFAULT_STATUS_TYPE;
    });
    let updatedBids = bids.map((i) => {
      i["seenStatus"] = true;
      return i;
    });
    let sortedBids = validBids.sort((i, j) => i.bid - j.bid);
    devConsolelog(
      "final validBids in bids data ",
      validBids,
      updatedBids,
      sortedBids
    );
    setBids(sortedBids);
    updateBidsSeenStatus(updatedBids);

  };
  const updateBidsSeenStatus = async (updatedBids = []) => {
    const reqRef = doc(db, "agentRequirements", reqId);
    try {
      await updateDoc(reqRef, {
        bids: updatedBids,
      });
    } catch (e) {
      devConsolelog("updateBidsSeenStatus error", e);
    }
  };

  useEffect(() => {
    if (JSON.stringify(req.bids) === JSON.stringify(bids)) return;
    if (!req.bids || req.bids.length == 0) return;
    devConsolelog("effect handleBids ", req.bids);
    handleBids(req.bids);
  }, [req.bids]);

  useEffect(() => {
    let unsubscribe = () => { };
    const getReq = async () => {
      devConsolelog("getReq ", reqRef);
      setIsLoading(true);
      unsubscribe = onSnapshot(
        reqRef,
        (snapshot) => {
          if (!snapshot.exists()) return;
          let data = snapshot.data();
          setReq(data);
          devConsolelog("got req data in bids ", data, data.bids);
          handleBids(data.bids || []);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          devConsolelog("view req error", reqRef, error);
        }
      );
    };
    getReq();

    return () => {
      unsubscribe();
    };
  }, []);

  const updateAndExpireReq = async (acceptedBid, bookingId) => {
    let reqBids = req.bids.map((b) => {
      if (b.id === acceptedBid.id) {
        return {
          ...b,
          accepted: true,
        };
      } else return b;
    });
    await updateDoc(reqRef, {
      bids: reqBids,
      status: REQ_BOOKED_STATUS_TYPE,
      bookingId,
    });
  };

  const createBooking = async (acceptedBid, tokenAmt, tokenScUrls) => {
    let reqData = { ...req };
    delete reqData.bids;
    let bookingId = `B-${nanoid()}`;
    devConsolelog("req for booking ", reqData, bookingId);
    let bookingData = {
      bookingId,
      acceptedBid: acceptedBid.bid,
      status: "initiated",  //"active",
      tokenPayment: {
        amount: tokenAmt,
        currency: 'inr',
        paymentScUrl: tokenScUrls[0],
        paymentDate: Date.now(),
        paymentVerified: false
      },
      supplierId: acceptedBid.supplierId,
      cabDetailsShared: false,
      driverDetailsShared: false,
      chats: [
        {
          id: nanoid(5),
          sender: BOOKING_CHAT_TYPE_SYSTEM,
          text: "CabEasy: Please chat directly with your booking partner here and share all the details necessary. *Any post booking dispute will be resolved only referring to data updated in this chat!",
          type: "text",
          createdAt: Date.now(),
        },
        {
          id: nanoid(5),
          sender: BOOKING_CHAT_TYPE_SYSTEM,
          text: `Token Amount paid to CabEasy: INR ${tokenAmt}`,
          type: "text",
          createdAt: Date.now(),
        },
        // {
        //   id : nanoid(5),
        //   createdAt: Date.now(),
        //   ctaDataAgent: [
        //       {
        //           actionType: "submit_arrival_details",
        //           name: "Share Client Arrival Details",
        //       },

        //   ],
        //   ctaDataSupp: [
        //       {
        //           actionType: "submit_cab_details",
        //           name: "Share Cab & Driver Details",
        //       }
        //   ],
        //   sender: "cb-system",
        //   // text : "Please View and Confirm exact Itinerary",
        //   textAgent: "Please share Client Arrival/Hotel Details",
        //   textSupp: "Please share the Cab&Details",
        //   type: "cta"
        // },
        SUBMIT_ARRIVAL_DETAILS,
        SUBMIT_CAB_DETAILS,
        PAYMENT_OPT
      ],
      req: {
        id: reqId,
        ...reqData,
      },
      createdAt: Date.now(),
    };
    let docRef = await setDoc(doc(db, "bookings", bookingId), bookingData);
    // setBookingCreated(true);
    // sendEmail({
    //   to: finalFormData.email, name: finalFormData.name,
    //   template: {
    //     name: "welcome_signup",
    //     data: {
    //       name: finalFormData.name,
    //     },
    //   }
    // });
    confirmNewBookingId.current = bookingId;
    setShowBookingTokenPaymentView({open: false});
    setOpenAccept(true);
    updateAndExpireReq(acceptedBid, bookingId); //docRef.id
    setBookLoading(false);
    handleBookingNoti(acceptedBid, bookingId);
  };

  const handleBookingNoti = async (acceptedBid, bookingId) => {
    let toAgent = userData.email,
      supplierData = await getUserDetails(acceptedBid.supplierId);
    let companyDetails = userData?.companyDetails || null;
    let companyName = companyDetails?.companyName;
    let toSupplier = supplierData?.email;
    devConsolelog(
      "booking noti",
      toAgent,
      toSupplier,
      userData,
      supplierData,
      bookingId,
      acceptedBid,
      req
    );

    sendEmail({
      to: toAgent,
      template: {
        name: "booking_created_agent",
        data: {
          name: companyName || userData.name,
          acceptedBid: acceptedBid.bid,
          bookingId,
          bookingDetailLink: `${process.env.REACT_APP_CABEASY_DOMAIN}/booking/${bookingId}`,
          pboConfirmItiLink: `${process.env.REACT_APP_CABEASY_DOMAIN}/booking/${bookingId}/${PBO_STEP_CONFIRM_ITI}`,
          pboArrDetailsLink: `${process.env.REACT_APP_CABEASY_DOMAIN}/booking/${bookingId}/${PBO_STEP_ARRIVAL_DETAILS}`,
          ...req,
          startDate: format(new Date(req.startDate), "dd-MMMM-yyyy") || "",
        },
      },
    });

    sendEmail({
      to: toSupplier,
      template: {
        name: "booking_created_supplier",
        data: {
          name: supplierData.name,
          acceptedBid: acceptedBid.bid,
          bookingId,
          bookingDetailLink: `${process.env.REACT_APP_CABEASY_DOMAIN}/booking/${bookingId}`,
          ...req,
          startDate: format(new Date(req.startDate), "dd-MMMM-yyyy") || "",
        },
      },
    });

    sendEmail({
      to: toAgent,
      template: {
        name: `b2c_booking_confirm_${req.destination.toLowerCase()}`,
        data: {
          companyName: companyName || userData.name,
          logo: companyDetails?.logo || "-",
          phone: userData.phone,
          email: userData.email,
          destination: req.destination,
          routesArr: req.route
            ? req.route
                ?.split("-")
                .map((i) => (i = i.replace("N)", " Nights)")))
            : "-",
          startDateView: format(new Date(req.startDate), "dd-MMMM-yyyy") || "",
          finalCompsOptsArr: req.finalCompsOpts
            ? Object.keys(req.finalCompsOpts)
            : [],
        },
      },
    });
    devConsolelog(
      "booking noti emails sent",
      `b2c_booking_confirm_${req.destination.toLowerCase()}`
    );

    const bid_cta_agent = {
      text: "Talk to Supplier",
      type: "success",
      link: `/booking/${bookingId}`,
    };
    addUserActivity(
      `Congrats! Got booking for ${req.destination} - ${req.trackingId}`,
      bid_cta_agent,
      userData.phone
    );
    const bid_cta_supplier = {
      text: "Chat with Agent",
      type: "success",
      link: `/booking/${bookingId}`,
    };
    addUserActivity(
      `Congrats! Got booking for ${req.destination} - ${req.trackingId}`,
      bid_cta_supplier,
      acceptedBid.supplierId
    );
  };

  const handleBidAccept = async (acceptedBid = null, tokenAmt, tokenScUrls = []) => {
    if (!acceptedBid || isEmptyObject(acceptedBid)) return;
    let newBookingId = await createBooking(acceptedBid, tokenAmt, tokenScUrls);
    devConsolelog("booking noti done..REDIRECT");
  };

  const showBookingTokenPopup = async (acceptedBid = null) => {
    if(!acceptedBid || isEmptyObject(acceptedBid)) return;
    setShowBookingTokenPaymentView({
      open: true,
      acceptedBid
    });
  };

  const handleBidDecline = async ({ id: bidId, bidDeclineMsg = "" }) => {
    if (!reqId || !bidId) return;
    devConsolelog("handleBidDecline called ", reqId, bidId);
    const reqRef = doc(db, "agentRequirements", reqId);
    let newBids = bids.map((b) => {
      if (b.id == bidId) {
        return {
          ...b,
          declineMsg: bidDeclineMsg,
          status: BID_DECLINED_STATUS_TYPE,
        };
      } else return b;
    });
    try {
      setIsLoading(true);
      await updateDoc(reqRef, {
        bids: newBids,
      });
      setIsLoading(false);
      setOpenDecline(false);
    } catch (e) {
      devConsolelog("handleBidDecline error", e);
      setIsLoading(false);
    }
  };

  const handleNegotiate = async (negotiateBid = null) => {
    if (!reqId || !negotiateBid || !negotiateBid.id) return;
    
    devConsolelog("handleNegotiate called ", reqId, negotiateBid.id);
  
    const reqRef = doc(db, "agentRequirements", reqId);
    let newBids = bids.map((b) => {
      if (b.id === negotiateBid.id) {
        return {
          ...b,
          agentNegotiateAttempts: (b.agentNegotiateAttempts || 0) + 1,
        };
      } else return b;
    });
  
    try {
      setIsLoading(true);
      await updateDoc(reqRef, {
        bids: newBids,
      });
      setIsLoading(false);
  
      await sendNegotiationNotification(negotiateBid);
      setOpenNegotiate(true);
    } catch (e) {
      devConsolelog("handleNegotiate error", e);
      setIsLoading(false);
    }
  };
  

  // Function to send negotiation notification to supplier
  const sendNegotiationNotification = async (negotiateBid) => {
    const bid_cta_supplier = {
      text: "Update your bid",
      type: "success",
      link: `/req/post-bid/${reqId}/update`,
    };
    addUserActivity(
      `Agent requested better price for the req ${req.destination} - ${req.trackingId}`,
      bid_cta_supplier,
      negotiateBid.supplierId 
    );
    console.log("Negotiation notification sent to supplier");
  };

  if (loading) {
    return <AppLoader />;
  }
  console.log("REQDATA_CHECK:", req, req?.bookingId, openAccept, !openAccept && req?.bookingId || false);
  return (
    <>
      <Helmet>
        <title>CabEasy: View Bids on your Requirement</title>
        <link rel="canonical" href="https://cabeasy.in/req/view-bids/" />
      </Helmet>

      <AppSubHeader name="Requirement Details" />
      <Box sx={{ maxWidth: 600, margin: "0px auto" }}>
        <DestReqCardIndex reqData={req} itemId={reqId} />
      </Box>
      <Typography
        color="text.secondary"
        variant="body1"
        component="div"
        sx={{ textAlign: "center", mt: 2 }}
      >
        {bids?.length <= 0 ? (
          <NoRecordsFound message={"Notified Suppliers. Bids coming up..."} />
        ) : (
          `You have received Bids from ${bids.length} trusted suppliers:`
        )}
      </Typography>
      <BidsList
        bids={bids}
        isAgent={userData.isAgent}
        handleBidAccept={handleBidAccept}
        handleBidDecline={handleBidDecline}
        handleNegotiation={handleNegotiate} 
        showBookingTokenPopup={showBookingTokenPopup}
        reqData = {req}
      />

      {showSnackbar && (
        <SnackbarMsg
          open={showSnackbar.open}
          message={showSnackbar.message}
          anchorOrigin={showSnackbar.anchorOrigin}
          severity={showSnackbar.severity || "success"}
          onClose={() => setShowSnackbar({ open: false })}
        />
      )}
      
      <PopUp
        open={openNegotiate}
        onClose={() => setOpenNegotiate(false)}
        onClick={() => setOpenNegotiate(false)}
        primaryText="Negotiation Request Sent"
        secondaryText1="We have let the supplier know that you’re willing to negotiate."
        secondaryText2="You'll be notified as soon as the supplier accepts." 
        secondaryText3="You can negotiate only once."
        submitText="OK"
      />
            
      <PopUp
        open={openAccept}
        checkMark={false}
        forwardRef={`/booking/${confirmNewBookingId.current}`}
        primaryText="Congratulations!"
        secondaryText="A booking is created for your requirement"
        secondaryText1="We will help you to manage and track this booking"
        secondaryText2="You can see the supplier details and also chat with them directly."
        secondaryText3="You will now be redirected to your booking details."
        submitText={"Chat with Supplier"}
      />
      <MyDailog
        showDailog={!openAccept && req?.bookingId || false}
        title="Alert"
        body={<>
          <Typography>This Requirement is already booked.</Typography>
          <br></br>
          <Button onClick={() => {
            navigate(`/booking/${req.bookingId}`)
          }}>Go to Booking</Button>
          <RedirectComponent />
        </>}

      ></MyDailog>
      <MyDailog 
        showDailog={showBookingTokenPaymentView.open} 
        title={"Ready to book? Please pay the token amount to Confirm a Booking"}
        body={<CEPayAccDetailsView 
          initiateBooking={handleBidAccept} 
          acceptedBid={showBookingTokenPaymentView.acceptedBid} 
          bookLoading={bookLoading}
          setBookLoading={setBookLoading}
        />}
        handleClose={() => setShowBookingTokenPaymentView({open: false})}
      />
    </>
  );
};

export default ViewBids;
