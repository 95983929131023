import SendIcon from "@mui/icons-material/Send";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import {
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { nanoid } from "nanoid";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { format, max } from "date-fns";
import { Helmet } from "react-helmet";
import { collection } from "firebase/firestore";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from 'axios';
import CircularProgress from "@mui/material/CircularProgress";

import DestReqCardIndex from "../Commons/destReqCardIndex.js";
import UpdateBidView from "./updateBid.js";
import { db } from "../firebaseConfig";
import {
  BID_DEFAULT_STATUS_TYPE,
  REQ_BOOKED_STATUS_TYPE,
  PHONE_FILTER_REGEX,
  BID_MSGS_MAX_LENGTH,
} from "../Constants";
import SnackbarMsg from "../Commons/snackbarMsg";
import BidsList from "./bidsList";
import {
  sendEmail,
  getUserDetails,
  devConsolelog,
  addUserActivity,
  MainContext
} from "../Utility.js";
import PopUp from "../Commons/messagePopUp.js";

const PostBid = ({ showUpdateBid }) => {
  let { state = {} } = useLocation();
  let { req: data = {} } = state || {};
  const [req, setReq] = useState(data);
  // const [suppReq, setSuppReq] = useState({});
  const [bid, setBid] = useState("");
  const [updateBidFlow, setUpdateBidFlow] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({ open: false });
  const [bidMsg, setBidMsg] = useState("");
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [rateInclusion, setRateInclusion] = useState("All Inclusive");
  const [cabModel, setCabModel] = useState("");
  let { reqId } = useParams();
  const navigate = useNavigate();
  const { showKycPopup } = useContext(MainContext);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const userData = JSON.parse(localStorage.getItem("user"));
  const isSuppVerified = (userData?.isSupplier && userData?.isKycVerified) || false;
  const docRef = doc(db, "agentRequirements", reqId);

  // const currentUserBid = useRef(false);
  // const listenerUnsubscribe = useRef();
  const [openSubmitSuccessPopup, setOpenSubmitSuccessPopup] = useState(false);
  const [priceAlert, setPriceAlert] = useState(null);
  const [extraPackagePrice, setExtraPackagePrice] = useState("");
  const [currentUserBid, setCurrentUserBid] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  let supRef = doc(db, "suppBidsPostedHistory", userData.phone)
  let {
    destination = "",
    cabType = "",
    agentId = "",
    pax = "",
    pickUpLoc = "",
    dropLoc = "",
    noOfNights = "",
    bids = [],
    minBudget = "",
    maxBudget = "",
    trackingId = "",
    needPackage = false,
  } = req;

  useEffect(() => {
    console.log("getLoggedInSupplierBids", req, bids);
    if (!bids || bids.length == 0) return;
    const getLoggedInSupplierBids = async () => {
      let currentUserBidData = bids.filter((item) => {
        console.log(
          "getLoggedInSupplierBids currentUserBid check ",
          item,
          item.supplierId == userData.phone,
          item.supplierId,
          userData.phone
        );
        return item.supplierId == userData.phone;
      });

      const sortedData = [...bids].sort((a, b) => parseInt(a.bid) - parseInt(b.bid));
      console.log("userBidIndex ", currentUserBidData, currentUserBidData[0], sortedData[0])
      if (currentUserBidData && currentUserBidData.length > 0) {
        const userBidIndex = sortedData.findIndex(bid => bid.id == currentUserBidData[0].id);
        const userRanking = userBidIndex !== -1 ? userBidIndex + 1 : null;
        // currentUserBid.current = Boolean(currentUserBid);
        currentUserBidData[0]['rank'] = userRanking;
        currentUserBidData[0]['totalLength'] = req.bids.length;
      }
      setCurrentUserBid(currentUserBidData);
    }


    // const checkAndGetData = async () => {
    //   if(!state && !data) {
    //     let req = await getReq();
    //     let { bids = [] } = req || [];
    //     let currentUserBid = bids.find(
    //       (item) => item.supplierId == userData.phone
    //     );
    //     currentUserBid.current = Boolean(currentUserBid);
    //   }
    // };
    // checkAndGetData();
    // return listenerUnsubscribe.current;

    getLoggedInSupplierBids();
  }, [bids]);

  useEffect(() => {
    if (currentUserBid && currentUserBid.length > 0 && showUpdateBid)
      setUpdateBidFlow(showUpdateBid);
  }, [currentUserBid, showUpdateBid]);

  useEffect(() => {
    let unsubscribe = () => { };
    const getReq = async () => {
      unsubscribe = onSnapshot(
        docRef,
        (snapshot) => {
          // ...
          if (!snapshot.exists()) return;
          let data = snapshot.data();
          console.log("got reqs data supplier ", data, snapshot);
          if (data.status == REQ_BOOKED_STATUS_TYPE) {
            handleReqBookedPopup({ ...req }, data);
          }
          setReq(data);
        },
        (error) => {
          console.log("view req error", docRef, error);
        }
      );
    };
    getReq();

    return () => unsubscribe();
  }, []);

  const handleReqBookedPopup = (prevData, updatedData) => {
    let acceptedBid = updatedData.bids.find((i) => i.accepted);
    console.log(
      "handleReqBookedPopup ",
      acceptedBid,
      acceptedBid.supplierId == userData.phone
    );
    if (acceptedBid.supplierId == userData.phone) {
      setShowSnackbar({
        message: "YOUR BID IS ACCEPTED! Redirecting you to your bookings.",
        open: true,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      setTimeout(() => {
        navigate("/my-bookings");
      }, 5000);
    }
  };

  const handleBidChange = (e) => {
    let val = e.target.value;

    let priceLeaway = 0.25 * maxBudget;
    if (parseInt(val) > parseInt(maxBudget) + priceLeaway) {
      setPriceAlert("high");
    } else if (parseInt(val) < parseInt(minBudget)) {
      setPriceAlert("low");
    } else {
      setPriceAlert(null);
    }

    // add validation for bids
    setBid(val);
  };

  const handlePost = async () => {
    if (!bid.trim() || currentUserBid.length > 0) return;
    console.log("handle bid post", bidMsg, bidMsg.length);
    if (bidMsg.length > BID_MSGS_MAX_LENGTH) {
      setShowSnackbar({
        message: `Max ${BID_MSGS_MAX_LENGTH} characters allowed for Message!`,
        open: true,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        severity: "error",
      });
      return; // Add a return here to stop the function if there's an error
    }
    setSubmitLoading(true);

    await updateDoc(docRef, {
      bids: arrayUnion({
        id: nanoid(4),
        supplierId: userData.phone,
        bid,
        message: bidMsg,
        extraPackagePrice,
        isNegotiable,
        rateInclusion: rateInclusion,
        cabModel,
        agentNegotiateAttempts: 0,
        status: BID_DEFAULT_STATUS_TYPE,
        createdAt: Date.now(),
        priceAlert: priceAlert,
      }),
      updatedAt: Date.now()
    });

    const supDataDoc = await getDoc(supRef);
    if (supDataDoc.exists()) {
      await updateDoc(supRef, {
        reqsWithBids:
          arrayUnion({
            reqId: reqId,
            bidAmt: parseInt(bid),
            bidMsg: bidMsg,
            postedAt: Date.now(),
            isNegotiable,
          })
      })

    }
    else {
      await setDoc(supRef, {
        reqsWithBids:
          [{
            reqId: reqId,
            bidAmt: parseInt(bid),
            bidMsg: bidMsg,
            postedAt: Date.now(),
            isNegotiable,
          }]
      });
    }

    let agentDetails = await getUserDetails(agentId);
    let { companyDetails = "" } = agentDetails;
    let { companyName = "" } = agentDetails;
    devConsolelog(
      "post bid mail to agent ",
      agentId,
      `${process.env.REACT_APP_CABEASY_DOMAIN}${process.env.REACT_APP_CHECK_BIDS_URL_PATH}${reqId}`
    );
    await sendEmail({
      to: agentId,
      // name: userData.name,
      template: {
        name: "bid_received_agent",
        data: {
          name: agentDetails?.companyName || agentDetails?.name,
          startDateView: format(new Date(req.startDate), "dd-MMMM-yyyy") || "",
          ...req,
          reqDetailLink: `${process.env.REACT_APP_CABEASY_DOMAIN}${process.env.REACT_APP_CHECK_BIDS_URL_PATH}${reqId}`,
        },
      },
    });

    // currentUserBid.current = true;
    // setTimeout(() => {
    //   navigate("/home")
    // }, 3000);

    const bid_message = `You posted a bid for ${req.destination} ${trackingId}`;
    const bid_cta = {
      text: "Check Bid status",
      link: `/req/post-bid/${reqId}`,
    };
    console.log(`You posted a bid for ${req}`);
    addUserActivity(bid_message, bid_cta, userData.phone);
    const bid_cta_agent = {
      text: "Check Bids",
      link: `/req/view-bids/${reqId}`,
    };
    addUserActivity(
      `You received ${bids?.length + 1} bid${bids.length > 0 ? "s" : ""} for ${req.destination
      } - ${trackingId}`,
      bid_cta_agent,
      req.agentId
    );

    const supplierStatsRef = doc(db, "userStatistics", userData.phone);
    const supplierStatsDoc = await getDoc(supplierStatsRef);
    if (supplierStatsDoc.exists()) {
      const currentTotalBidsPosted =
        supplierStatsDoc.data().totalBidsPosted || 0;
      await updateDoc(supplierStatsRef, {
        totalBidsPosted: currentTotalBidsPosted + 1,
      });
    } else {
      await setDoc(supplierStatsRef, {
        totalBidsPosted: 1,
      });
    }

    await handleBookingWANoti();
    setSubmitLoading(false);
    setOpenSubmitSuccessPopup(true);
  };

  const handleBookingWANoti = async () => {
    const axiosOptions = {
      method: 'POST',
      headers: { 
          // 'content-type': 'application/x-www-form-urlencoded',
          'Authorization': userData?.firebaseIdToken 
      },
      data: {
        "reqId": reqId,
        "agentId": req.agentId,
        "bid": {
          "bid_amt": bid,
          "message": bidMsg,
          "supplierId": userData.phone,
          "rateInclusive": rateInclusion
        }
      },
      url: `${process.env.REACT_APP_CE_API_BASE_URL}/api/req/post-bid/notifications/`,
    };
    console.log('post bid noti axios ', axiosOptions);

    let response = await axios(axiosOptions);
    console.log('post bid noti response axios ', response);
  }

  const handleUpdateBidView = (val) => setUpdateBidFlow(val);

  console.log("post bid render ", currentUserBid);

  return (
    <>
      <Helmet>
        <title>CabEasy: Post Bid on a Requirement</title>
        <link rel="canonical" href="https://cabeasy.in/req/post-bid/" />
      </Helmet>

      {showSnackbar && (
        <SnackbarMsg
          open={showSnackbar.open}
          message={showSnackbar.message}
          anchorOrigin={showSnackbar.anchorOrigin}
          severity={showSnackbar.severity || "success"}
          onClose={() => setShowSnackbar({ open: false })}
        />
      )}
      <Box>
        <Box sx={{ mt: 4 }}>
          <center>
            <DestReqCardIndex reqData={req} itemId={reqId} />
            {updateBidFlow ? (
              <UpdateBidView
                handleUpdateBidView={handleUpdateBidView}
                prevBid={currentUserBid[0]}
                req={req}
                setShowSnackbar={setShowSnackbar}
                setUpdateBidFlow={setUpdateBidFlow}
              />
            ) : (
              <Box sx={{ mt: { xs: 5, m: 10 }, maxWidth: 545 }}>
                {currentUserBid.length > 0 ? (
                  <p style={{ background: "#fff7db" }}>
                    Your bid request has been submitted. Please wait while it's
                    being reviewed by the agent. Once it's approved, you'll be
                    notified.
                  </p>
                ) : (
                  <>
                    <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Your Bid (only Cabs)*
                      </InputLabel>
                      <OutlinedInput
                        id="bidInput"
                        startAdornment={
                          <InputAdornment position="start">INR</InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">{rateInclusion ? `/${rateInclusion}` : ''}</InputAdornment>
                        }
                        label="post your bid"
                        onChange={(e) => handleBidChange(e)}
                        type="number"
                      />
                      {priceAlert === "high" && (
                        <Typography variant="caption" color="error">
                          Your price seems too high for this requirement.
                          <br />
                          Please ensure this is your best price.
                        </Typography>
                      )}
                    </FormControl>
                    <FormControl sx={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>
                      <FormLabel id="demo-radio-buttons-group-label" sx={{ m: 'auto', maxWidth: isMobile ? '40%' : '100%' }} color="secondary">Select your Rate Inclusions:</FormLabel>
                      <RadioGroup
                        row={!isMobile}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="All Inclusive"
                        name="radio-buttons-group"
                        onChange={(e) => setRateInclusion(e.target.value)}
                      >
                        <FormControlLabel value="All Inclusive" control={<Radio size="small" />} label="All Inclusive" />
                        <FormControlLabel value="Parking Not Included" control={<Radio size="small" />} label="Parking not Included" />
                        <FormControlLabel value="State Tax Not Included" control={<Radio size="small" />} label="State not Included" />
                      </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Mention extra cost, exclusions here..
                      </InputLabel>
                      <OutlinedInput
                        id="bidMsgInput"
                        label="Message to agent"
                        onChange={(e) =>
                          setBidMsg(
                            e.target.value.replace(PHONE_FILTER_REGEX, " ")
                          )
                        }
                        type="text"
                      />
                    </FormControl>
                    <br />
                    <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                      <InputLabel>
                      Cab Model Options
                      </InputLabel>
                      <OutlinedInput
                        id="cabModelInput"
                        onChange={(e) => setCabModel(e.target.value.replace(PHONE_FILTER_REGEX, " "))}
                      />
                    </FormControl>
                    <br />
                    {needPackage && (
                      <FormControl fullWidth sx={{ m: "8px 0px", flexGrow: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Bid including Hotels/Package(Optional)
                        </InputLabel>
                        <OutlinedInput
                          id="extraPackagePrice"
                          startAdornment={
                            <InputAdornment position="start">
                              INR
                            </InputAdornment>
                          }
                          onChange={(e) =>
                            setExtraPackagePrice(
                              e.target.value.replace(PHONE_FILTER_REGEX, " ")
                            )
                          }
                          type="number"
                        />
                      </FormControl>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isNegotiable}
                          onChange={(e) => setIsNegotiable(e.target.checked)}
                        />
                      }
                      label="Price is Negotiable"
                    />
                  </>
                )}
              </Box>
            )}

            {currentUserBid.length <= 0 && (
              <Button
                sx={{ mr: 1, flexGrow: 1, m: "8px 0px" }}
                variant="contained"
                // size="small"
                onClick={isSuppVerified ? handlePost : () => showKycPopup(true)}
                disabled={currentUserBid.length > 0}
                endIcon={<SendIcon />}
              >
                Post Bid
              </Button>
            )}
            {
              submitLoading && <CircularProgress color={"primary"} size={40} sx={{ m: 1 }} />
            }
          </center>
        </Box>
        <br />
        {currentUserBid.length > 0 && (
          <center>
            <Typography variant="subtitle2" component="div">
              Your Bids:
            </Typography>
            <BidsList
              bids={currentUserBid}
              handleUpdateBidView={handleUpdateBidView}

            />
          </center>
        )}
      </Box>
      <div style={{ margin: "1rem auto", maxWidth: "10em" }}>
        <Button
          onClick={() => navigate(-1)}
          fullWidth
          variant="text"
          color="secondary"
        >
          Go back
        </Button>
      </div>
      <PopUp
        open={openSubmitSuccessPopup}
        onClose={() => setOpenSubmitSuccessPopup(false)}
        checkMark={true}
        primaryText="Your Bid is posted and the Agent is notified"
        secondaryText1="We will tell you if the Agent sees your bid, you will see the icon."
        secondaryText2="If the Agent accepts your bid, Booking will be automatically created on 'My Bookings' page"
        secondaryText3="The Agent and you can see each other's details and chat directly on the platform."
        forwardRef="/my-reqs"
        submitText="Active Requirements"
      />
    </>
  );
};

export default PostBid;
